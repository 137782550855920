import { scroller } from "react-scroll";
import ymaps from "yandex-maps";

export interface StringObject {
  [key: string]: string;
}

export interface FilterQuery {
  [key: string]: string | undefined;
  federal_district?: string;
  region?: string;
  city?: string;
  industry?: string;
  profession?: string;
}

export interface FilterResponce {
  [key: string]: {};
  federal_district: { [key: string]: string };
  region: { [key: string]: string };
  city: { [key: string]: string };
  industry: { [key: string]: string };
  profession: { [key: string]: string };
}

export interface SearchQuery {
  [key: string]: string;
  federal_district: string;
  region: string;
  city: string;
  industry: string;
  profession: string;
}

export interface SearchResponse {
  [key: string]: string | number;
  created_at: string;
  updated_at: string;
  federal_district: string;
  region: string;
  city: string;
  profession: string;
  industry: string;
  index: string;
  mom: string;
  yoy: string;
  slice_id: string;
  upload_date: string;
  event_month: string;
  schedule: string;
  median_salary: string;
  salary_mom: string;
  salary_yoy: string;
}

export interface IRegionCollection {
  [key: string]: ymaps.GeoObject;
}

export interface DistrictCollectionProperties extends ymaps.IDataManager {
  name?: string;
}

export interface DistrictCollection extends ymaps.GeoObjectCollection {
  properties: DistrictCollectionProperties;
}

export interface IDistrictCollection {
  [key: string]: DistrictCollection;
}

export const districtByRegion: StringObject = {
  "RU-BEL": "Центральный федеральный округ",
  "RU-BRY": "Центральный федеральный округ",
  "RU-VLA": "Центральный федеральный округ",
  "RU-VOR": "Центральный федеральный округ",
  "RU-IVA": "Центральный федеральный округ",
  "RU-KLU": "Центральный федеральный округ",
  "RU-KOS": "Центральный федеральный округ",
  "RU-KRS": "Центральный федеральный округ",
  "RU-LIP": "Центральный федеральный округ",
  "RU-MOS": "Центральный федеральный округ",
  "RU-MOW": "Центральный федеральный округ",
  "RU-ORL": "Центральный федеральный округ",
  "RU-RYA": "Центральный федеральный округ",
  "RU-SMO": "Центральный федеральный округ",
  "RU-TAM": "Центральный федеральный округ",
  "RU-TVE": "Центральный федеральный округ",
  "RU-TUL": "Центральный федеральный округ",
  "RU-YAR": "Центральный федеральный округ",
  "RU-ARK": "Северо-Западный федеральный округ",
  "RU-VLG": "Северо-Западный федеральный округ",
  "RU-KGD": "Северо-Западный федеральный округ",
  "RU-KR": "Северо-Западный федеральный округ",
  "RU-KO": "Северо-Западный федеральный округ",
  "RU-LEN": "Северо-Западный федеральный округ",
  "RU-MUR": "Северо-Западный федеральный округ",
  "RU-NEN": "Северо-Западный федеральный округ",
  "RU-NGR": "Северо-Западный федеральный округ",
  "RU-PSK": "Северо-Западный федеральный округ",
  "RU-SPE": "Северо-Западный федеральный округ",
  "RU-AD": "Южный федеральный округ",
  "RU-AST": "Южный федеральный округ",
  "RU-VGG": "Южный федеральный округ",
  "RU-KL": "Южный федеральный округ",
  "RU-KDA": "Южный федеральный округ",
  "RU-SEV": "Южный федеральный округ",
  "RU-KRY": "Южный федеральный округ",
  "RU-ROS": "Южный федеральный округ",
  "RU-DA": "Северо-Кавказский федеральный округ",
  "RU-IN": "Северо-Кавказский федеральный округ",
  "RU-KB": "Северо-Кавказский федеральный округ",
  "RU-KC": "Северо-Кавказский федеральный округ",
  "RU-SE": "Северо-Кавказский федеральный округ",
  "RU-STA": "Северо-Кавказский федеральный округ",
  "RU-CE": "Северо-Кавказский федеральный округ",
  "RU-BA": "Приволжский федеральный округ",
  "RU-KIR": "Приволжский федеральный округ",
  "RU-ME": "Приволжский федеральный округ",
  "RU-MO": "Приволжский федеральный округ",
  "RU-NIZ": "Приволжский федеральный округ",
  "RU-ORE": "Приволжский федеральный округ",
  "RU-PNZ": "Приволжский федеральный округ",
  "RU-PER": "Приволжский федеральный округ",
  "RU-SAM": "Приволжский федеральный округ",
  "RU-SAR": "Приволжский федеральный округ",
  "RU-TA": "Приволжский федеральный округ",
  "RU-UD": "Приволжский федеральный округ",
  "RU-ULY": "Приволжский федеральный округ",
  "RU-CU": "Приволжский федеральный округ",
  "RU-KGN": "Уральский федеральный округ",
  "RU-SVE": "Уральский федеральный округ",
  "RU-TYU": "Уральский федеральный округ",
  "RU-KHM": "Уральский федеральный округ",
  "RU-CHE": "Уральский федеральный округ",
  "RU-YAN": "Уральский федеральный округ",
  "RU-ALT": "Сибирский федеральный округ",
  "RU-AL": "Сибирский федеральный округ",
  "RU-BU": "Сибирский федеральный округ",
  "RU-ZAB": "Сибирский федеральный округ",
  "RU-IRK": "Сибирский федеральный округ",
  "RU-KEM": "Сибирский федеральный округ",
  "RU-KYA": "Сибирский федеральный округ",
  "RU-NVS": "Сибирский федеральный округ",
  "RU-OMS": "Сибирский федеральный округ",
  "RU-TOM": "Сибирский федеральный округ",
  "RU-TY": "Сибирский федеральный округ",
  "RU-KK": "Сибирский федеральный округ",
  "RU-AMU": "Дальневосточный федеральный округ",
  "RU-YEV": "Дальневосточный федеральный округ",
  "RU-KAM": "Дальневосточный федеральный округ",
  "RU-MAG": "Дальневосточный федеральный округ",
  "RU-PRI": "Дальневосточный федеральный округ",
  "RU-SA": "Дальневосточный федеральный округ",
  "RU-SAK": "Дальневосточный федеральный округ",
  "RU-KHA": "Дальневосточный федеральный округ",
  "RU-CHU": "Дальневосточный федеральный округ",
};

export const allDistricts: StringObject = {
  "Центральный федеральный округ": "Центральный федеральный округ",
  "Северо-Западный федеральный округ": "Северо-Западный федеральный округ",
  "Южный федеральный округ": "Южный федеральный округ",
  "Северо-Кавказский федеральный округ": "Северо-Кавказский федеральный округ",
  "Приволжский федеральный округ": "Приволжский федеральный округ",
  "Уральский федеральный округ": "Уральский федеральный округ",
  "Сибирский федеральный округ": "Сибирский федеральный округ",
  "Дальневосточный федеральный округ": "Дальневосточный федеральный округ",
};

export const districtMap: StringObject = {
  "Центральный федеральный округ": "Центральном ФО",
  "Северо-Западный федеральный округ": "Северо-Западном ФО",
  "Южный федеральный округ": "Южном ФО",
  "Северо-Кавказский федеральный округ": "Северо-Кавказском ФО",
  "Приволжский федеральный округ": "Приволжском ФО",
  "Уральский федеральный округ": "Уральском ФО",
  "Сибирский федеральный округ": "Сибирском ФО",
  "Дальневосточный федеральный округ": "Дальневосточном ФО",
}

export const monthNames = [
  "январь",
  "февраль",
  "март",
  "апрель",
  "май",
  "июнь",
  "июль",
  "август",
  "сентябрь",
  "октябрь",
  "ноябрь",
  "декабрь",
];

export const mapMonths = {
  "январь": "январе",
  "февраль": "феврале",
  "март": "марте",
  "апрель": "апреле",
  "май": "мае",
  "июнь": "июне",
  "июль": "июле",
  "август": "августе",
  "сентябрь": "сентябре",
  "октябрь": "октябре",
  "ноябрь": "ноябре",
  "декабрь": "декабре"
}

export const regionOnMapByApiRegion: StringObject = {
  "Адыгея": "Республика Адыгея",
  "Алтайский край": "Алтайский край",
  "Амурская область": "Амурская область",
  "Архангельская область": "Архангельская область",
  "Астраханская область": "Астраханская область",
  "Башкортостан": "Республика Башкортостан",
  "Белгородская область": "Белгородская область",
  "Брянская область": "Брянская область",
  "Бурятия": "Республика Бурятия",
  "Владимирская область": "Владимирская область",
  "Волгоградская область": "Волгоградская область",
  "Вологодская область": "Вологодская область",
  "Воронежская область": "Воронежская область",
  "Дагестан": "Республика Дагестан",
  "Еврейская АО": "Еврейская автономная область",
  "Забайкальский край": "Забайкальский край",
  "Ивановская область": "Ивановская область",
  "Ингушетия": "Республика Ингушетия",
  "Иркутская область": "Иркутская область",
  "Кабардино-Балкария": "Кабардино-Балкарская Республика",
  "Калининградская область": "Калининградская область",
  "Калмыкия": "Республика Калмыкия",
  "Калужская область": "Калужская область",
  "Камчатский край": "Камчатский край",
  "Карачаево-Черкесия": "Карачаево-Черкесская Республика",
  "Карелия": "Республика Карелия",
  "Кемеровская область": "Кемеровская область",
  "Кировская область": "Кировская область",
  "Коми": "Республика Коми",
  "Костромская область": "Костромская область",
  "Краснодарский край": "Краснодарский край",
  "Красноярский край": "Красноярский край",
  "Крым": "Республика Крым",
  "Курганская область": "Курганская область",
  "Курская область": "Курская область",
  "Ленинградская область": "Ленинградская область",
  "Липецкая область": "Липецкая область",
  "Магаданская область": "Магаданская область",
  "Марий Эл": "Республика Марий Эл",
  "Мордовия": "Республика Мордовия",
  "Москва": "Москва",
  "Московская область": "Московская область",
  "Мурманская область": "Мурманская область",
  "Ненецкий АО": "Ненецкий автономный округ",
  "Нижегородская область": "Нижегородская область",
  "Новгородская область": "Новгородская область",
  "Новосибирская область": "Новосибирская область",
  "Омская область": "Омская область",
  "Оренбургская область": "Оренбургская область",
  "Орловская область": "Орловская область",
  "Пензенская область": "Пензенская область",
  "Пермский край": "Пермский край",
  "Приморский край": "Приморский край",
  "Псковская область": "Псковская область",
  "Республика Алтай": "Республика Алтай",
  "Ростовская область": "Ростовская область",
  "Рязанская область": "Рязанская область",
  "Самарская область": "Самарская область",
  "Санкт-Петербург": "Санкт-Петербург",
  "Саратовская область": "Саратовская область",
  "Саха (Якутия)": "Республика Саха (Якутия)",
  "Сахалинская область": "Сахалинская область",
  "Свердловская область": "Свердловская область",
  "Северная Осетия": "Республика Северная Осетия - Алания",
  "Смоленская область": "Смоленская область",
  "Ставропольский край": "Ставропольский край",
  "Татарстан": "Республика Татарстан",
  "Тамбовская область": "Тамбовская область",
  "Томская область": "Томская область",
  "Тульская область": "Тульская область",
  "Тыва": "Республика Тыва",
  "Тюменская область": "Тюменская область",
  "Удмуртия": "Удмуртская Республика",
  "Ульяновская область": "Ульяновская область",
  "Хабаровский край": "Хабаровский край",
  "Хакасия": "Республика Хакасия",
  "Ханты-Мансийский АО": "Ханты-Мансийский автономный округ",
  "Челябинская область": "Челябинская область",
  "Чеченская Республика": "Чеченская Республика",
  "Чувашия": "Чувашская Республика",
  "Чукотский АО": "Чукотский автономный округ",
  "Ямало-Ненецкий АО": "Ямало-Ненецкий автономный округ",
  "Ярославская область": "Ярославская область",
};

export function scrollToInfo() {
  scroller.scrollTo("map-container", {
    smooth: 'ease',
    duration: 1200,
    delay: 500,
    isDynamic: true,
    offset: -50,
  });
}

export const DEFAULT_COLOR = "#EBEBEB";
export const STROKE_COLOR = "#FDFDFD";
export const HIGHLIGHT_COLOR = "#04e061";

export function imgUrl(src: string): string {
  return `url('${src}')`;
}
