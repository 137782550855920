import React, {ReactElement, useEffect, useRef} from "react";
/* eslint-disable */
import useAnimation from "../hooks/useAnimation";
import CloseIcon from "./svg/closeIcon";
import { enableBodyScroll, disableBodyScroll, BodyScrollOptions } from "body-scroll-lock";

interface ModalProps {
  children: ReactElement | ReactElement[],
  isActive: boolean,
  onClose: () => void,
}

const bodyScrollOptions: BodyScrollOptions = {
  reserveScrollBarGap: true,
}

export default function Modal({ children, isActive, onClose }: ModalProps) {
  useEffect(() => {
    const element = modalContainerRef.current as HTMLElement
    if (isActive) {
      disableBodyScroll(element, bodyScrollOptions);
    } else {
      enableBodyScroll(element)
    }
  }, [isActive]);


  const modalContainerRef = useRef<HTMLDivElement>(null);
  useAnimation({
    element: modalContainerRef.current as HTMLElement,
    isActive
  });
  return (
    <div className="modal" ref={modalContainerRef}>
      <div className="modal__content">
        <button className="modal__close" onClick={onClose}>
          <CloseIcon />
        </button>
        { children }
      </div>
    </div>
  )
}

export type ModalTypes = 'form' | 'success' | 'error' | null;
