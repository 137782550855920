import React from "react";

type footerLink = {
  title: string;
  url: string;
}

export default function Footer() {
    const footerLinks: footerLink[] = [
        { title: 'Разместить объявление', url: 'avito.ru/additem' },
        { title: 'Объявления', url: 'avito.ru/rossiya' },
        { title: 'Помощь', url: 'support.avito.ru/' },
        { title: 'Безопасность', url: 'avito.ru/safety' },
        { title: 'Реклама на сайте', url: 'avito.ru/advertising' },
        { title: 'О компании', url: 'avito.ru/company' },
        { title: 'Блог', url: 'www.avito.ru/blog?tags=Работа' },
        { title: 'Мобильное приложение', url: 'avito.ru/info/apps' },
    ];
    return (
        <footer>
            <div className="footer-links-container">
                <ul className="footer-links">
                    {footerLinks.map(({ title, url }) => (
                        <li key={title}>
                            <a href={`https://${url}`} target="_blank" rel="noreferrer">
                                { title }
                            </a>
                        </li>
                    ))
                    }
                </ul>
            </div>
            <div className="footer-credentials">
                Авито —{" "}
                <a href="https://www.avito.ru/" target="_blank" rel="noreferrer">
                    сайт объявлений России.
                </a>{" "}
                © ООО «КЕХ еКоммерц» 2007–2023.{" "}
                <a href="https://www.avito.ru/legal/" target="_blank" rel="noreferrer">
                    Правила Авито.
                </a>{" "}
                <a href="https://www.avito.ru/legal/rules/privacy-policy" target="_blank" rel="noreferrer">
                    Политика обработки данных.
                </a>{" "}
                Оплачивая услуги на Авито, вы принимаете{" "}
                <a href="https://www.avito.ru/legal/rules/services-agreement" target="_blank" rel="noreferrer">
                    оферту.
                </a>
            </div>
        </footer>
    )
}
