import React, {ReactElement, useRef, useState} from "react";
/* eslint-disable */

interface IAccordionProps {
  title: string;
  children: ReactElement | string;
}

export default function Accordion({ title, children }: IAccordionProps): ReactElement {
 const [opened, setOpened] = useState<boolean>(false);
 const contentRef = useRef(null);

 return (
   <div className={`accordion${opened ? ' opened' : ''}`} onClick={() => setOpened(!opened)}>
     <div className="accordion__title">
       { title }
     </div>
     <div className="accordion__content" ref={contentRef} style={{
       // @ts-ignore
       maxHeight: opened ? `${contentRef.current?.scrollHeight}px` : '0px',
     }}>
       { children }
     </div>
   </div>
 )
}
