import React from "react";

export default function RubleIcon() {
  return (
    <svg width="35" height="50" viewBox="0 0 35 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.9808 22.054C31.9808 23.6282 31.7128 25.0349 31.177 26.2741C30.6746 27.4799 29.9545 28.5014 29.0166 29.3388C28.0788 30.1426 26.9568 30.7622 25.6506 31.1977C24.3443 31.6331 22.9041 31.8508 21.3299 31.8508H9.62402V45.6165H5.10243V12.2572H21.3299C22.9041 12.2572 24.3443 12.4749 25.6506 12.9103C26.9568 13.3457 28.0788 13.9821 29.0166 14.8194C29.9545 15.6233 30.6746 16.6448 31.177 17.8841C31.7128 19.0898 31.9808 20.4798 31.9808 22.054ZM27.4592 22.054C27.4592 20.2453 26.8898 18.8721 25.751 17.9343C24.6123 16.963 23.1386 16.4773 21.3299 16.4773H9.62402V27.6306H21.3299C23.1386 27.6306 24.6123 27.1617 25.751 26.2239C26.8898 25.2526 27.4592 23.8626 27.4592 22.054Z" fill="white"/>
      <rect x="0.832031" y="36.1396" width="30.1252" height="3.92937" fill="white"/>
    </svg>
  )
}
