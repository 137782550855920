import React, {useEffect, useRef, useState} from "react";
// @ts-ignore
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
// @ts-ignore

export default function Header({ isIndex }: { isIndex: boolean }) {
    const [ menuActive, setMenuActive ] = useState(false);
    const menuRef = useRef(null);
    // const location = useLocation();

    function toggleMenu() {
        setMenuActive(!menuActive);
    }

    useEffect(() => {
        (menuActive ? disableBodyScroll : enableBodyScroll)(menuRef.current as unknown as HTMLElement, { reserveScrollBarGap: true });
    }, [menuActive]);

    return (
        <header className="header" >
            <div className="header__wrapper container">
                <a href="https://www.avito.ru/" className="header__logo">
                    <img src="avito-logo.svg" alt="avito" className="header__logo-img"/>
                </a>
                <nav className="header__nav">
                    <ul className="header__nav-items hidden-tablet">
                      {  isIndex ? null :
                        <li className="header__nav-item">
                        <a href="/">
                          Индекс рынка труда
                        </a>
                      </li> }
                      <li className="header__nav-item">
                        <a href="/about">
                          О проекте
                        </a>
                      </li>
                    </ul>
                    <div className="header__menu visible-tablet">
                        <button type="button" className={`header__menu-btn${ menuActive ? ' active' : ''}`} onClick={toggleMenu}>
                            <span className="header__menu-btn-item" />
                            <span className="header__menu-btn-item" />
                            <span className="header__menu-btn-item" />
                        </button>
                        <div className={`header__menu-el${menuActive ? ' active' : ''}`} ref={menuRef}>
                            <nav className="header__menu-nav">
                                <ul className="header__menu-nav-items">
                                  {  isIndex ? null :
                                    <li className="header__menu-nav-item">
                                      <a href="/" className="header__menu-nav-link">
                                        Индекс рынка труда
                                      </a>
                                    </li> }
                                  <li className="header__menu-nav-item">
                                    <a href="/about" className="header__menu-nav-link">
                                      О проекте
                                    </a>
                                  </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}
