import React from "react";

const tableContent = {
  header: [
    'Значения индекса',
    'Что это значит?',
    'Что делать?',
  ],
  rows: [
    ['от 1 до 2', '<b>Рынок соискателя</b> вакансий больше, чем кандидатов', [
      `Расширять воронку сверху. Например, можно искать кандидатов в других регионах и любого возраста.`,
      `Продумать комфортные условия для кандидата — гибкий график, удалённую работу, неполный день.`,
      `Убедиться, что зарплата не меньше, чем у конкурентов.`
    ]],
    ['от 2 до 4', '<b>Преимущественно рынок соискателя</b> кандидатов немного не хватает', [
      `Указать в вакансии, чем компания отличается от конкурентов: хорошим соцпакетом, лояльным руководством, отсутствием бюрократии.`,
      `Расширять воронку сверху. Например, можно искать кандидатов в других регионах и любого возраста.`,
      `Продумать комфортные условия для кандидата — гибкий график, удалённую работу, неполный день.`
    ]],
    ['от 4 до 6', '<b>Рынок в балансе</b> спрос и предложение равны', [
      `Предлагать зарплату не ниже, чем у конкурентов.`,
      `Указать в вакансии, чем компания отличается от конкурентов: хорошим соцпакетом, лояльным руководством, отсутствием бюрократии.`,
      `Оперативно реагировать на отклики, чтобы вас не опередили другие работодатели.`
    ]],
    ['от 6 до 8', '<b>Преимущественно рынок работодателя</b> конкуренции за кандидатов почти нет', [
      'Указать в вакансии, чем компания отличается от конкурентов: хорошим соцпакетом, лояльным руководством, отсутствием бюрократии.',
      'Попробовать инструменты продвижения, чтобы вакансия была заметнее. На Авито можно повысить количество просмотров объявления, увеличить его или выделить цветом зарплату.'
    ]],
    ['от 8 до 10', '<b>Рынок работодателя</b> кандидатов больше, чем вакансий', [
      'Работать над брендом работодателя, чтобы привлекать лучших кандидатов. Например, повысить узнаваемость, снизить «текучку», продумать соцпакет.',
      'Автоматизировать подбор сотрудников. Например, на Авито можно делегировать первичный отбор чат-боту.'
    ]]

  ]
}

export default function Table() {
  return (
    <div className="container">
      <div className="table__wrapper">
        <div className="table">
          <div className="table__row">
            {tableContent.header.map((item) => (
              <div
                className="table__item table__header"
                key={item}
              >
                { item }
              </div>
            ))}
          </div>
            {tableContent.rows.map((row) => (
              <div
                className="table__row"
                key={`${row[0]}row`}
              >
                {row.map((item, i) => (
                  (typeof item === 'string') ?
                    <div
                      className={`table__item${!i ? ' table__header' : ''}`}
                      dangerouslySetInnerHTML={{ __html: item }}
                      key={item}
                    /> :
                    <ul
                      className="table__item table__item-list"
                      key={item[0]}
                    >
                      { item.map((li) => (
                        <li key={li}>{ li }</li>
                      )) }
                    </ul>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
