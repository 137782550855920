/* eslint-disable */
import React, {FC} from "react";

interface ProgressLabelProps {
  minLabel: string | React.ReactElement;
  maxLabel: string | React.ReactElement;
}

const ProgressLabel: FC<ProgressLabelProps> = ({minLabel, maxLabel}) => (
  <div className="progress-bar__labels">
    <div className="progress-bar__min">
      {minLabel}
    </div>
    <div className="progress-bar__max">
      {maxLabel}
    </div>
  </div>
)

interface ProgressBarProps {
  min: number;
  max: number;
  value: number;
  hasTooltip?: boolean;
  hasCaption?: boolean;
  children?: JSX.Element;
}

const ProgressBar: FC<ProgressBarProps> = ({min, max, value, hasTooltip, children, hasCaption}) => {
  const percentage = (value / max) * 100;
  const minValue = (+min).toLocaleString('ru-RU');
  const maxValue = (+max).toLocaleString('ru-RU');
  return (
    <div className="progress-bar">
      {
        hasCaption ? <ProgressLabel minLabel={minValue} maxLabel={maxValue}/> : null
      }
      <div className="progress-bar__fill">
        <div
          className={ `progress-bar__mark${hasTooltip ? ' _tooltip' : ''}` }
          style={{ left: `${percentage}%` }}
        >
          <div className={`progress-bar__mark__arrow ${hasCaption && (percentage < 5 || percentage > 95) ? 'top' : ''}`} />
          <div className="progress-bar__mark__line" />
        </div>
        { children }
      </div>
      <ProgressLabel
        minLabel={ hasCaption ? <>Рынок<br/> соискателя</> : minValue }
        maxLabel={ hasCaption ? <>Рынок<br/> работодателя</> : maxValue }
      />
    </div>
  );
};

export default ProgressBar;
